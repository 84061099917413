import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home"),
  },
  {
    path: "/tellfree",
    name: "tellfree",
    component: () => import("../views/tollfree"),
  },
  {
    path: "/printing",
    name: "printing",
    component: () => import("../views/printing"),
  },
  {
    path: "/printspeed",
    name: "printspeed",
    component: () => import("../views/printing/printspeed.vue"),
  },
  {
    path: "/printfail",
    name: "printfail",
    component: () => import("../views/printing/printfail.vue"),
  },
  {
    path: "/groupshop",
    name: "groupshop",
    component: () => import("../views/groupshop"),
  },
  {
    path: "/charge",
    name: "charge",
    component: () => import("../views/charge"),
  },
  {
    path: "/healthy",
    name: "healthy",
    component: () => import("../views/tollfree"),
  },
  {
    path: "/tellphone",
    name: "tellphone",
    component: () => import("../views/tellphone"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
