import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible"; //rem
import {
  Carousel,
  CarouselItem,
  Dialog,
  Button,
  Input,
  Drawer,
  Progress
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Input);
Vue.use(Drawer);
Vue.use(Progress);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
